import axios from "axios";
import { getStorage, removeStorage, setStorage1 } from "../helper";

const ROOT_STATE_STORAGE_KEY = "root";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
});

let isRefreshing = false;
const refreshSubscribers = [];
const subscribeTokenRefresh = (cb) => {
    refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
    refreshSubscribers.map((cb) => cb(token));
};

const request = async (config) => {
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    if (!storeData) return config;
    const data = JSON.parse(storeData);
    if (config.url.indexOf("/authenticate/refresh") === -1 && data?.tokens?.access_token) {
        config.headers.Authorization = `Bearer ${data?.tokens?.access_token}`;
    }
    if (config.url.indexOf("/authenticate/refresh") !== -1 && data?.tokens?.refresh_token) {
        config.headers.Authorization = `Bearer ${data?.tokens?.refresh_token}`;
    }
    return config;
};

const response = (response) => {
    return Promise.resolve(response?.data);
};
const responseError = async (error) => {
    const {
        config,
        response: { status }
    } = error;
    const storeData = await getStorage(ROOT_STATE_STORAGE_KEY);
    const data = JSON.parse(storeData);
    const originalRequest = config;
    if ( status === 401 && config.url.indexOf( "/authenticate/refresh" ) !== -1 ) {
        removeStorage( ROOT_STATE_STORAGE_KEY );
        if(typeof window !== "undefined") window.location.reload();
    }
    else if (status === 401) {
        if (!isRefreshing) {
            isRefreshing = true;
            instance.get( "/authenticate/refresh" ).then( async ( response ) => {

                if ( response ) {
                    await setStorage1( ROOT_STATE_STORAGE_KEY, JSON.stringify( { ...data, tokens: response } ) );
                }
                isRefreshing = false;
                onRefreshed(response?.access_token);
            });
        }

        const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh((token) => {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(instance(originalRequest));
            });
        });
        return retryOrigReq;
    }
    return Promise.reject(error);
};
const requestError = async (error) => {
    return Promise.reject(error);
};
instance.interceptors.request.use(request, requestError);

instance.interceptors.response.use(response, responseError);

export default instance;
