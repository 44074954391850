import { useCallback, useState } from "react";
import { 
    addressListUrl, 
    authenticateMeUrl, 
    orderUrl, productPriceUrl, productSkuDetailUrl,
    userContinueUrl, userListUrl } from "services";
import useSWR from "swr";
import { SideBarItems } from "constant/SideBar";
import { useAuth } from "context";
import { get, post } from "helper";

export const useUser = (UserID) => {
    const URL = `${userContinueUrl}${UserID}`;
    const fetcher = async () => await get(URL);
    const { data, mutate, error } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const useUserList = () => {
    const URL = userListUrl;
    const fetcher = async () => await post(URL);
    const { data, mutate, error } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const useAddress = (params) => {
    const URL = addressListUrl;
    const fetcher = async () => await post(URL, params);
    const { data, mutate, error } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const useProduct = ( ProductID ) => {
    const URL = `${productSkuDetailUrl}${ProductID}`;
    const fetcher = async () => await post(URL, {});
    const { data, mutate, error } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const useOrder = ( orderID ) => {
    const URL = `${orderUrl}/${ orderID }`;
    const fetcher = async () => await get(URL);
    const { data, mutate, error } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const useToggle = (initialState = false) => {
    const [state, setState] = useState(initialState);
    const toggle = useCallback(() => setState(state => !state), []);
    return [state, toggle];
};

export const useCurrentUser = () => {
    const fetcher = () => get(authenticateMeUrl);
    const { data, error } = useSWR(authenticateMeUrl, location.pathname.indexOf("login") == -1 && fetcher);
    const loading = !data && !error;
    return { data, error, loading };
};

export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });
    const setValue = (value) => {
        try {
            const valueToStore =
          value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue];
};


export const usePost = (ListUrl, queryParams) => {
    const fetcher = async () => await post(ListUrl, queryParams);
    const { data, mutate:mutateData, error, isValidating, isLoading } = useSWR(ListUrl, () => fetcher(queryParams));
    const mutate = async () => await mutateData(fetcher(queryParams));
    return { data, error, isLoading, isValidating, mutate };
};

export const usePersistedJsonState = (key, initialState) => {
    let rawStateFromStorage = undefined;
    if (typeof window !== "undefined") {
        rawStateFromStorage = localStorage.getItem(key);
    }
    const stateFromStorage =
      rawStateFromStorage && JSON.parse(rawStateFromStorage);
  
    const [value, setter] = useState(stateFromStorage || initialState);
  
    return [
        value,
        data => {
            localStorage.setItem(key, JSON.stringify(data));
            setter(data);
        }
    ];
};

export const usePersistedState = (key, initialState) => {
    let stateFromStorage = undefined;
    if (typeof window !== "undefined") {
        stateFromStorage = localStorage.getItem(key);
    }
    const [value, setter] = useState(stateFromStorage || initialState);
  
    return [
        value,
        data => {
            localStorage.setItem(key, data);
            setter(data);
        }
    ];
};

export const useAllowedSidebarItems = () => {
    const { user } = useAuth();
    if(!user?.is_admin){
        const allowedSideBarItems = SideBarItems.map((item) => {
            const newChildren = item?.children;
            if (newChildren) {
                const filteredChildren = newChildren?.filter(
                    (child) => user?.permissions?.indexOf(child?.permissionkey) !== -1
                );
                return { ...item, children: filteredChildren };
            } else return item;
        }).filter((item) => item.children.length !== 0);
        return allowedSideBarItems ;
    }

    return SideBarItems ;
};

export const usePermission = ( roleID ) => {
    const URL = `/permission/list_permissions/${ roleID }`;
    const fetcher = async () => await get(URL);
    const { data, mutate, error } = useSWR(roleID?URL:null, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, mutateData };
};

export const usePrice = ( PriceID ) => {
    const URL = `${ productPriceUrl}/${PriceID}`;
    const fetcher = async () => await get(URL);
    const { data, mutate, error, isLoading, isValidating } = useSWR(URL, fetcher);
    const mutateData = async () => await mutate(fetcher());
    return { data, error, isLoading, isValidating, mutateData };
};
