import { Checkbox, Col, Collapse, Form, Input, Radio, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { addressCountiesUrl, citiesAddressListUrl, companyListUrl } from "services";
import CustomNumberInput from "./CustomNumberInput";
import { useFilter } from "context";
import { get, getAddAddressInitialFormValues, post, put } from "helper";
import { useAddress } from "helper/Hook";
import { notificationError } from "helper/NotificationHelpers";
import { formatResult } from "helper/TableHelpers";
import { Url } from "routes/account/address/Constant";
import { getAddViewInitialValues, handleFormSubmitQuery } from "routes/account/address/Helper";

const AddView = ({ form }) => {
    const { Panel } = Collapse;
    const { id: primaryKey } = useParams();
    const location = useLocation();
    const { queryParams } = useFilter();
    const { mutateData } = useAddress(queryParams);

    const url = location.pathname.split("/");
    const path = url?.at(-1);
    const companyId = url?.at(3);

    const [data, setData] = useState(undefined);
    const [visible, setVisible] = useState(false);
    const [companies, setCompanies] = useState(undefined);
    const [cities, setCities] = useState(undefined);
    const [counties, setCounties] = useState(undefined);
    const [email, setEmail] = useState({ value: "" });

    const getDataViaPrimaryKey = async () => {
        if (primaryKey && path !== "overview") {
            const { result, success, message } = await get(`${Url}/${primaryKey}`);
            if (!success) {
                notificationError(message);
                return;
            }
            const initialFormValues = getAddAddressInitialFormValues(result);
            form.setFieldsValue(initialFormValues);
            setData(result);
            if (result.city.id) getCounties(result.city.id);
        } else {
            form.setFieldsValue({ company_id : companyId, type: "company" });
        }
    };

    const getCompanies = async () => {
        const { result, success, message } = await post(companyListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCompanies(formatResult(result));
    };

    const getCities = async () => {
        const { result, success, message } = await get(citiesAddressListUrl, {
            filter: { active: 1 },
            pageSize: 1000
        });
        if (!success) {
            notificationError(message);
            return;
        }
        setCities(formatResult(result));
    };

    const getCounties = async (cityid) => {
        const { result, success, message } = await get(
            `${addressCountiesUrl}${cityid}`,
            {
                filter: { city_id: cityid },
                pageSize: 1000
            }
        );
        if (!success) {
            notificationError(message);
            return;
        }
        setCounties(formatResult(result));
    };

    const emailHandleChange = (e) => {
        const emailInput = e.target.value;
        if (emailInput.length < 5 && !emailInput.includes("@")) return;
        else {
            setEmail((prevValue) => ({ ...prevValue, value: emailInput }));
        }
    };

    const onChangeCityId = (e) => {
        getCounties(e);
    };

    const onClose = async () => {
        await setVisible(false);
    };

    const handleSave = async (values) => {
        const query = handleFormSubmitQuery(values);
        const { success, message } =
        !primaryKey || path === "overview"
            ? await post(`${Url}/add`, query)
            : await put(`${Url}/${primaryKey}`, query);
        if (!success) {
            notificationError(message);
            return;
        }
        setData(undefined);
        onClose();
        if(companyId) {
            form.resetFields();
            form.setFieldsValue({ company_id : companyId, type: "company" });
        }
        mutateData();
    };

    useEffect(() => {
        setVisible(true);
        getDataViaPrimaryKey();
        getCompanies();
        getCities();
    }, []);

    useEffect(() => {
        if (visible) {
            if (data) {
                const initialValues = getAddViewInitialValues(data);
                form.setFieldsValue(initialValues);
            }
        } else return;
    }, [data, visible]);

    return (
        <Form
            form={ form }
            layout="vertical"
            requiredMark={ "Required" }
            onFinish={ handleSave }
        >
            <Form.Item
                name="is_corporate"
                valuePropName={ "checked" }
                style={ { width: "100%" } }
            >
                <Checkbox>Kurumsalda Göster</Checkbox>
            </Form.Item>
            <FormItem
                label="Adres Tipi"
                name="type"
                rules={ [{ message: "Lütfen adres tipi seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Radio.Group>
                    <Radio value={ "company" }>Şube Adresi</Radio>
                    <Radio value={ "shipment" }>Sevkiyat Adresi</Radio>
                </Radio.Group>
            </FormItem>
            <Form.Item
                label="Firma"
                name="company_id"
                rules={ [{ message: "Lütfen firma seçiniz!", required: true }] }
                style={ { width: "100%" } }
            >
                <Select
                    placeholder="Lütfen firma seçiniz"
                    showSearch
                    allowClear
                    disabled={ url?.at(2) === "company" ? true : false }
                    filterOption={ (inputValue, option) =>
                        option.children?.toLowerCase().includes(inputValue.toLowerCase())
                    }
                >
                    { companies?.map((option) => (
                        <Select.Option key={ option.value }>{ option.label }</Select.Option>
                    )) }
                </Select>
            </Form.Item>
            <Form.Item
                label="Adres Başlığı"
                name="name"
                rules={ [{ message: "Lütfen adres başlığı giriniz!", required: true }] }
            >
                <Input placeholder="Adreslerde görünmesini istediğiniz bir başlık yazınız" />
            </Form.Item>
            <Form.Item
                label="Açıklama"
                name="title"
                rules={ [{ message: "Lütfen adres başlığı giriniz!" }] }
            >
                <Input placeholder="Adresle ilişkili ad soyad girebilirsiniz" />
            </Form.Item>
            <Row gutter={ 16 }>
                <Col span={ 12 }>
                    <Form.Item
                        label="İl"
                        name="city_id"
                        autoComplete="on"
                        rules={ [{ message: "Lütfen il seçiniz!", required: true }] }
                        style={ { width: "100%" } }
                    >
                        <Select
                            showSearch
                            placeholder="Lütfen il seçiniz"
                            allowClear
                            filterOption={ (inputValue, option) =>
                                option.children
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            }
                            onChange={ onChangeCityId }
                        >
                            { cities?.map((option) => (
                                <Select.Option key={ option.value }>{ option.label }</Select.Option>
                            )) }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={ 12 }>
                    <Form.Item
                        label="İlçe"
                        name="county_id"
                        rules={ [{ message: "Lütfen ilçe seçiniz!", required: true }] }
                        style={ { width: "100%" } }
                    >
                        <Select
                            showSearch
                            placeholder="Lütfen ilçe seçiniz"
                            allowClear
                            filterOption={ (inputValue, option) =>
                                option.children
                                    ?.toLowerCase()
                                    .includes(inputValue.toLowerCase())
                            }
                        >
                            { counties?.map((option) => (
                                <Select.Option key={ option.value }>{ option.label }</Select.Option>
                            )) }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                label="Adres "
                name="address1"
                rules={ [
                    { message: "Lütfen Adres bilgilerini giriniz!", required: true }
                ] }
            >
                <TextArea />
            </Form.Item>
            <FormItem>
                <Collapse ghost>
                    <Panel header="İletişim Bilgileri" key="1">
                        <Form.Item
                            label="E-Posta adresi"
                            name="email"
                            rules={ [
                                {
                                    message: "Lütfen email adresinizi giriniz!"
                                },
                                {
                                    message: "Lütfen email adresinizi giriniz!",
                                    type: "email"
                                }
                            ] }
                            validateStatus={ email?.validateStatus }
                            help={ email?.errorMsg }
                            hasFeedback
                        >
                            <Input
                                onChange={ emailHandleChange }
                                value={ email.value }
                                placeholder="Lütfen e-posta adresi yazınız"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Gsm"
                            name="phone1"
                            rules={ [
                                {
                                    max: 9,
                                    message: "Telefon numarası 11 karakter olmalıdır!",
                                    min: 9
                                }
                            ] }
                        >
                            <CustomNumberInput digitsOfNumber={ 9 } prefix="+90 5" />
                        </Form.Item>
                        <Form.Item
                            label="Telefon"
                            name="phone2"
                            rules={ [
                                {
                                    max: 9,
                                    message: "Telefon numarası 11 karakter olmalıdır!",
                                    min: 9
                                }
                            ] }
                        >
                            <CustomNumberInput digitsOfNumber={ 9 } prefix="+90 5" />
                        </Form.Item>
                    </Panel>
                </Collapse>
            </FormItem>
            <FormItem>
                <Collapse ghost>
                    <Panel header="Vergi Dairesi Ve Numarası" key="1">
                        <Form.Item
                            label="Vergi Dairesi "
                            name="tax_office"
                            rules={ [{ message: "Lütfen Adres açıklama giriniz!" }] }
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Vergi Numarası"
                            name="tax_number"
                            rules={ [
                                {
                                    max: 11,
                                    message: "Telefon numarası 10-11 karakter olmalıdır!",
                                    min: 10
                                }
                            ] }
                        >
                            <CustomNumberInput digitsOfNumber={ 11 } />
                        </Form.Item>
                    </Panel>
                </Collapse>
            </FormItem>
        </Form>
    );
};
export default AddView;
