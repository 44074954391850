/* eslint-disable no-restricted-imports */
import { CloudDownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Progress, Row, Space } from "antd";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { syncCompanyUrl } from "services";
import { getFilterQuery } from "../Helper";
import { ProtectedElement } from "components";
import { DrawerComp } from "components/common/Drawer";
import { DrawerForm } from "components/common/DrawerForm";
import { FilterButton } from "components/common/FilterButton";
import { useFilter } from "context";
import { notificationError, post, useSyncProgress } from "helper";

const Filter = () => {
    const { queryParams, isDefaultParams, setQueryParams, searchText, setSearchText } = useFilter();
    const push = useNavigate();
    const [ form ] = Form.useForm();
    const { progressError, progressItem1, delayItem1, syncProgress }
    =useSyncProgress( "company" );

    const [open, setOpen] = useState(false);
    const [buttonStyle, setButtonStyle] = useState("no-filter");

    const syncCompanyList = async () => {
        const { success, message } = await post(syncCompanyUrl);
        if (!success) {
            notificationError(message);
            return;
        }
        syncProgress();
    };

    const applyFilter = (values) => {
        const newQueryParams = getFilterQuery(queryParams, values);
        if (!isEqual(newQueryParams, queryParams)) {
            newQueryParams.pageNumber = 1;
            setQueryParams(newQueryParams);
        }
    };

    const handleAdd = () => push("add");

    const handleInputChange = ({ target }) =>{       
        const name = target?.value;
        setSearchText(name);     
        applyFilter({ ...queryParams.filter, name });
    };

    const handleSubmit = (values) => {
        if (searchText) values.name = searchText;
        applyFilter({ ...queryParams.filter, ...values });
    };

    useEffect(() => {
        isDefaultParams
            ? setButtonStyle("no-filter-btn-style")
            : setButtonStyle("filter-btn-style");
    }, [isDefaultParams]);
    
    return (
        <>
            <Row gutter={ 24 }>
                <Col span={ 6 }>
                    <div className="mb-10">
                        <Input
                            onChange={ handleInputChange }
                            placeholder="Firma ismi"
                            value={ searchText }
                        />
                    </div>
                </Col>
                <Col className="ml-auto">
                    <Space>
                        <ProtectedElement permissionKey={ "sync.add" }>  
                            <Button
                                shape="round"
                                type="dashed"
                                onClick={ () => syncCompanyList() }
                                block
                                icon={ <CloudDownloadOutlined /> }
                                disabled={ progressItem1 !== 100 }
                            >
                                Güncelle
                            </Button>
                        </ProtectedElement>
                        <ProtectedElement permissionKey={ "company.add" }>  
                            <Button
                                shape="round"
                                type="dashed"
                                onClick={ handleAdd }
                                block
                                icon={ <PlusOutlined /> }
                            >
                                Yeni
                            </Button>
                        </ProtectedElement>
                        <FilterButton setOpen={ setOpen } buttonStyle={ buttonStyle } />
                        <DrawerComp
                            handleSubmit={ handleSubmit }
                            setOpen={ setOpen }
                            open={ open }
                            form={ form }
                            type="edit"
                        >
                            < DrawerForm form={ form } handleSubmit={ handleSubmit } />
                        </DrawerComp>
                    </Space>
                </Col>
            </Row>
            { ((progressItem1 !== 100 || !delayItem1) && !progressError) && (
                <>
                    <Row>
                        <p>Hesaplar Güncelleniyor</p>
                    </Row>
                    <Progress percent={ progressItem1 } status="active" className="mb-20"/>
                </>
            ) } 
        </>
    );
};
export default Filter;
